import AddAPhoto from '@mui/icons-material/AddAPhoto';
import { CircularProgress } from '@mui/material';
import DragAndDrop from 'components/DragAndDrop';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

type Props = {
    handleDrop: (e: any) => Promise<void>;
    uploadFiles: (e: any) => Promise<void>;
    cancelUpload?: () => void;
    handlePastedText?: (text: string) => void;
    getDataFromClipboardEnabled?: boolean;
    clipboardImageEnabled?: boolean;
    fileInputRef: React.RefObject<HTMLInputElement>;
    allowedFiletypes?: string;
    notValid?: boolean;
    multiple?: boolean;
    small?: boolean;
    hoverFontSize?: number;
    fontSize?: number;
    className?: string;
};

const FileUpload = ({
    handleDrop,
    uploadFiles,
    cancelUpload,
    handlePastedText,
    getDataFromClipboardEnabled,
    clipboardImageEnabled,
    fileInputRef,
    allowedFiletypes,
    notValid,
    multiple,
    small,
    hoverFontSize,
    fontSize = 13,
    ...props
}: Props) => {
    const [fileIsLoading, setFileIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleUploadClick = () => {
        fileInputRef?.current?.click();
    };

    const _handleDrop = async (e: any) => {
        setFileIsLoading(true);
        handleDrop?.(e).finally(() => {
            setFileIsLoading(false);
        });
    };

    const _uploadFiles = async (e: any) => {
        setFileIsLoading(true);
        uploadFiles?.(e.target.files).finally(() => {
            setFileIsLoading(false);
        });
    };

    return (
        <StyledRectangle
            disabled={fileIsLoading}
            onClick={() => (!fileIsLoading ? handleUploadClick() : null)}
            {...props}
            notValid={notValid}
        >
            <DragAndDrop
                hoverFontSize={hoverFontSize}
                onDrop={_handleDrop}
                onPastedText={handlePastedText}
                getDataFromClipboardEnabled={getDataFromClipboardEnabled}
                clipboardImageEnabled={clipboardImageEnabled}
                disabled={fileIsLoading}
            >
                {fileIsLoading ? (
                    <>
                        <CircularProgress />
                        <Flex className="mt-1">
                            <span>{t('upload.processingFileUpload')}</span>
                            <CancelButton
                                className="f1-200"
                                fontSize={fontSize}
                                onClick={() => {
                                    cancelUpload?.();
                                    setFileIsLoading(false);
                                }}
                            >
                                {t('upload.cancel')}
                            </CancelButton>
                        </Flex>
                    </>
                ) : (
                    <>
                        <AddAPhoto sx={{ marginBottom: '1rem' }} />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('upload.dragAndDropOrBrowse', 'Drag and drop or <u>browse</u>'),
                            }}
                        />
                        {allowedFiletypes && (
                            <span>
                                {t('upload.allowedType', 'Allowed file types')}: {allowedFiletypes}
                            </span>
                        )}
                    </>
                )}
                <HiddenInput
                    multiple={multiple}
                    id={'customer-file-upload'}
                    type={'file'}
                    accept={allowedFiletypes}
                    ref={fileInputRef}
                    onChange={_uploadFiles}
                />
            </DragAndDrop>
        </StyledRectangle>
    );
};

const StyledRectangle = styled.div<{ notValid?: boolean; disabled?: boolean }>`
    width: 100%;
    height: 100%;
    border: 1px dashed ${({ notValid }) => (!notValid ? 'gray' : 'red')};
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    ${({ disabled }) => disabled && 'pointer-events: none;'};
`;

const Flex = styled.div`
    display: flex;
`;

const HiddenInput = styled.input`
    margin-top: 2rem;
    display: none;
    /* display: block; */
`;

const CancelButton = styled.a<{ fontSize?: number }>`
    pointer-events: all;
    border: none;
    padding: 0;
    font-size: 12px;
    color: black;
`;

export default FileUpload;
