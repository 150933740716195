import { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import { useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { scopeMap } from '../auth-config';

type Props = {
    onClose: () => void;
    open: boolean;
};

export const AccountPicker = (props: Props) => {
    const { instance, accounts } = useMsal();
    const { onClose, open } = props;

    const handleListItemClick = (account: AccountInfo | null) => {
        instance.setActiveAccount(account);
        if (!account) {
            instance.loginRedirect({
                ...scopeMap.login,
                prompt: 'login',
            });
        } else {
            // To ensure account related page attributes update after the account is changed
            window.location.reload();
        }

        onClose();
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Set active account</DialogTitle>
            <List>
                {accounts.map((account) => (
                    <ListItem button onClick={() => handleListItemClick(account)} key={account.homeAccountId}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={account.name} secondary={account.username} />
                    </ListItem>
                ))}

                <ListItem onClick={() => handleListItemClick(null)}>
                    <ListItemAvatar>
                        <Avatar>
                            <AddIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Add account" />
                </ListItem>
            </List>
        </Dialog>
    );
};
