import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import { scopeMap } from 'auth-config';
import { ErrorComponent } from 'components/ErrorComponent';
import { Loading } from 'components/Loading';
import SwissArmyUpload from 'components/SwissArmyUpload';

const SwissArmyContent = () => {
    return (
        <Box sx={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <SwissArmyUpload />
        </Box>
    );
};

export const SwissArmy = () => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={scopeMap.hotfolder}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <SwissArmyContent />
        </MsalAuthenticationTemplate>
    );
};
