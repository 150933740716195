import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Input,
    ListItemText,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { ActiveDeactivateType, ActiveDeactiveResult } from 'hotfile-api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import services from 'services';
import styled from 'styled-components/macro';
import chunkIt from 'utils/chunkIt';
import FileUpload from './FileUpload';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

type ActionStatuses = { [key: string]: ActiveDeactiveResult } | undefined;

type Upload = {
    inProgress: boolean;
    count?: number;
    total?: number;
};

const SwissArmyUpload = () => {
    const { instance, inProgress } = useMsal();
    const { t } = useTranslation();
    const fileInput = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [items, setItems] = useState<string[]>([]);
    const [actionStatuses, setActionStatus] = useState<ActionStatuses>({});
    const [operationType, setOperationType] = useState<ActiveDeactivateType>(ActiveDeactivateType.ActivateCompany);
    const [operationKey, setOperationKey] = useState<string | undefined | null>();
    const [readingFile, setReadingFile] = useState<boolean>(false);
    const [upload, setUpload] = useState<Upload>({
        inProgress: false,
        count: 0,
        total: 0,
    });

    useEffect(() => {
        console.log('RUNNING', files);
        if (files && files.length > 0 && inProgress === InteractionStatus.None) {
            setReadingFile(true);
            services.swissarmy
                .upload({ files: files })
                .then((response: any) => {
                    setActionStatus({});
                    setItems((prev) => [...new Set([...(prev ?? [])].concat(response))]);
                    setReadingFile(false);
                    setFiles([]);
                })
                .finally(() => {
                    setReadingFile(false);
                });
        }

        if (!fileInput.current) {
            return;
        }

        const dataTransfer = new DataTransfer();
        files.map((file) => dataTransfer.items.add(file));
        fileInput.current.files = dataTransfer.files;
    }, [files, instance, inProgress, operationType, items]);

    const uploadFiles = useCallback(async (files: FileList) => {
        if (!(files.length > 0)) {
            return;
        }

        await setFiles([...files]);
    }, []);

    const performAction = useCallback(async () => {
        const itemsInChunks = chunkIt(items ?? [], 20);

        console.log(operationKey);
        console.log(operationType);

        setActionStatus({});
        await setUpload((prev) => ({
            ...prev,
            inProgress: true,
            total: itemsInChunks.length + 1,
            count: 0,
        }));

        Promise.all(
            itemsInChunks.map(async (chunkedItem: string[]) => {
                return services.swissarmy
                    .activateInactive({ items: chunkedItem, type: operationType, key: operationKey })
                    .then(async (response) => {
                        setActionStatus(
                            (prev) =>
                                ({
                                    ...prev,
                                    ...response.reduce(
                                        (acc, item: ActiveDeactiveResult) => ({
                                            ...acc,
                                            [`${item.item}`]: item,
                                        }),
                                        {},
                                    ),
                                } as ActionStatuses),
                        );

                        await setUpload((prev) => ({
                            ...prev,
                            count: prev.count! < prev.total! ? prev.count! + 1 : prev.count,
                        }));
                    })
                    .catch(async () => {
                        await setUpload((prev) => ({
                            ...prev,
                            count: prev.count! < prev.total! ? prev.count! + 1 : prev.count,
                        }));
                    });
            }),
        ).finally(async () => {
            await setUpload((prev) => ({
                ...prev,
                count: prev.count! + 1,
            }));

            await setTimeout(() => {
                setUpload((prev) => ({ ...prev, inProgress: false }));
            }, 1000);
        });
    }, [items, operationType, operationKey]);

    const operationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.value) {
            case 'activate_company':
                setOperationType(ActiveDeactivateType.ActivateCompany);
                break;
            case 'deactivate_company':
                setOperationType(ActiveDeactivateType.DeactivateCompany);
                break;
            case 'activate_channel':
                setOperationType(ActiveDeactivateType.ActivateChannel);
                break;
            case 'deactivate_channel':
                setOperationType(ActiveDeactivateType.DeactivateChannel);
                break;
        }
    };

    const getOperationValue = (operationType: ActiveDeactivateType): string => {
        switch (operationType) {
            case ActiveDeactivateType.ActivateCompany:
                return 'activate_company';
            case ActiveDeactivateType.DeactivateCompany:
                return 'deactivate_company';
            case ActiveDeactivateType.ActivateChannel:
                return 'activate_channel';
            case ActiveDeactivateType.DeactivateChannel:
                return 'deactivate_channel';
        }
    };

    return (
        <Box>
            <Box sx={{ marginBottom: '2rem', display: 'flex', flexDirection: 'column' }}>
                <StyledFileUpload
                    fileInputRef={fileInput}
                    handleDrop={uploadFiles}
                    uploadFiles={uploadFiles}
                    getDataFromClipboardEnabled
                    clipboardImageEnabled={true}
                    multiple={true}
                    allowedFiletypes={t('upload.supportedXlsx', '.xlsx, .txt, .csv') ?? ''}
                />
                <FormControl sx={{ marginBottom: '2rem' }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                        {t('upload.operationType', 'Operation')}
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={getOperationValue(operationType)}
                        onChange={operationTypeChange}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FormControlLabel
                                    value="activate_company"
                                    control={<Radio />}
                                    label=" Company - Activate"
                                />
                                <FormControlLabel
                                    value="deactivate_company"
                                    control={<Radio />}
                                    label=" Company - Deactivate"
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FormControlLabel
                                    value="activate_channel"
                                    control={<Radio />}
                                    label="Channel - Activate"
                                />
                                <FormControlLabel
                                    value="deactivate_channel"
                                    control={<Radio />}
                                    label="Channel - Deactivate"
                                />
                            </Box>
                        </Box>
                    </RadioGroup>
                    <FormLabel sx={{ marginTop: '0.5rem' }} id="demo-row-radio-buttons-group-label">
                        {operationType === ActiveDeactivateType.ActivateCompany ||
                        operationType === ActiveDeactivateType.DeactivateCompany
                            ? t('upload.operationTypeCompany', 'Company')
                            : t('upload.operationTypeChannel', 'Channel')}
                    </FormLabel>
                    <Input
                        size="small"
                        type="text"
                        sx={{ width: '6rem' }}
                        onChange={(event) => setOperationKey(event.target.value)}
                    ></Input>
                </FormControl>
                <Box sx={{ maxWidth: 1000, display: 'flex' }}>
                    <Button
                        disabled={
                            !(items?.length && items?.length > 0) ||
                            readingFile ||
                            operationKey === undefined ||
                            operationKey == null ||
                            operationKey.length === 0
                        }
                        variant="contained"
                        color="success"
                        size="small"
                        type="submit"
                        onClick={performAction}
                        sx={{ marginRight: 'auto' }}
                    >
                        {operationType === ActiveDeactivateType.DeactivateChannel ||
                        operationType === ActiveDeactivateType.DeactivateCompany
                            ? t('upload.deactivate', 'Deactivate')
                            : t('upload.active', 'Activate')}
                    </Button>
                    <Button
                        disabled={items?.length === 0 || readingFile}
                        color="error"
                        size="small"
                        type="submit"
                        startIcon={<DeleteIcon color="inherit" />}
                        onClick={() => setItems([])}
                    >
                        {t('upload.clear', 'Clear')}
                    </Button>
                </Box>
            </Box>
            <TableContainer component={Box}>
                <Table sx={{ maxWidth: 1000 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '10%' }}>No.</TableCell>
                            <TableCell sx={{ width: '30%' }}>Status</TableCell>
                            <TableCell sx={{ width: '60%' }}>Code</TableCell>
                            <TableCell sx={{ width: '10%' }} align="right"></TableCell>
                        </TableRow>
                    </TableHead>

                    {readingFile ? (
                        <TableBody>
                            <TableRow sx={{ border: 0 }}>
                                <TableCell colSpan={3} sx={{ border: 0, alignItems: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : items?.length === 0 ? null : (
                        <TableBody>
                            {items?.map((item, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ width: '10%' }} scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }} scope="row">
                                        {actionStatuses?.[item]?.result ?? ''}
                                    </TableCell>
                                    <TableCell sx={{ width: '60%' }}>
                                        <ListItemText
                                            key={index}
                                            sx={{ wordBreak: 'break-all' }}
                                            primary={item}
                                            secondary={item}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }} align="right">
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            size="small"
                                            color="error"
                                            onClick={() => setItems((prev) => (prev ?? []).filter((i) => item !== i))}
                                        >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {upload.inProgress && (
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={upload.inProgress}>
                    <Box sx={{ background: '#fff', borderRadius: 2, p: 2 }}>
                        <LinearProgressWithLabel inProgress={true} count={upload.count} total={upload.total} />
                    </Box>
                </Backdrop>
            )}
        </Box>
    );
};

const StyledFileUpload = styled(FileUpload)`
    padding: 1rem;
    margin-bottom: 1rem;
    width: 700px;
    height: 230px;
`;

export default SwissArmyUpload;
