import { Configuration } from 'hotfile-api';
import { msalInstance } from '../index';

type options = {
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
    url: string;
    body?: any;
    multipartFormData?: boolean;
};

type headers = {
    accessToken?: string;
    scopes: string[];
};
export const getAccessToken = async ({ accessToken, scopes }: headers) => {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
        }

        const response = await msalInstance.acquireTokenSilent({
            scopes,
            account: account,
        });
        accessToken = response.accessToken;
    }
    return accessToken;
};
const getHeaders = async ({ accessToken, scopes }: headers) => {
    accessToken = await getAccessToken({ accessToken, scopes });

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return headers;
};

const customRequest = async (options: options, baseURL: string, scopes: string[]) => {
    const { body } = options;

    const headers = await getHeaders({ scopes });
    if (body && options.multipartFormData) {
        if (!(body instanceof FormData)) {
            const formData = new FormData();
            for (const key in body) {
                const content = body[key];

                if (content && content.length > 1) {
                    for (const element of content) {
                        formData.append(key, element);
                    }
                } else {
                    formData.append(key, content);
                }
            }
            options.body = formData;
        }
        headers.delete('Content-Type');
        headers.delete('Accept');
    }

    if (body && !options.multipartFormData) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(`${baseURL}${options.url}`, { ...options, headers })
            .then((response) => {
                return response
                    .json()
                    .then((json) => {
                        return json;
                    })
                    .catch(() => null);
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    } catch (err) {
        throw err;
    }
};

const api = (baseURL: string, scopes: string[]) => ({
    get: (url: string) => customRequest({ method: 'GET', url }, baseURL, scopes),
    post: (url: string, body: any, multipartFormData?: boolean) =>
        customRequest({ method: 'POST', url, body, multipartFormData }, baseURL, scopes),
    put: (url: string, body: any) => customRequest({ method: 'PUT', url, body }, baseURL, scopes),
    patch: (url: string, body: any) => customRequest({ method: 'PATCH', url, body }, baseURL, scopes),
    delete: (url: string, body: any) => {
        if (!body) {
            return customRequest({ method: 'DELETE', url }, baseURL, scopes);
        }
        return customRequest({ method: 'DELETE', url, body }, baseURL, scopes);
    },
});

export const createApi = (baseURL: string, scopes: string[], Api: any) => {
    const config = new Configuration({
        basePath: baseURL,
        accessToken: async () => {
            return await getAccessToken({ scopes: scopes });
        },
        middleware: [
            {
                pre: async function (context) {
                    const bearer = `Bearer ${await config.accessToken?.()}`;
                    const headers = new Headers(context.init.headers);

                    headers.append('Authorization', bearer);
                    context.init.headers = headers;

                    return Promise.resolve();
                },
            },
        ],
    });

    return {
        clientCustom: api(baseURL, scopes),
        client: new Api(config),
    };
};

export default api;
