"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotfileApi = void 0;
var runtime = require("../runtime");
var models_1 = require("../models");
/**
 *
 */
var HotfileApi = /** @class */ (function (_super) {
    __extends(HotfileApi, _super);
    function HotfileApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     */
    HotfileApi.prototype.apiHotfileItemsActivatePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.type !== undefined) {
                            queryParameters['type'] = requestParameters.type;
                        }
                        if (requestParameters.key !== undefined) {
                            queryParameters['key'] = requestParameters.key;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/items/activate",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: requestParameters.requestBody,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.ActiveDeactiveResultFromJSON); })];
                }
            });
        });
    };
    /**
     */
    HotfileApi.prototype.apiHotfileItemsActivatePost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileItemsActivatePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Endpoint posting string that contains items
     */
    HotfileApi.prototype.apiHotfileItemsDataPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.data !== undefined) {
                            queryParameters['data'] = requestParameters.data;
                        }
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/items/data",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Endpoint posting string that contains items
     */
    HotfileApi.prototype.apiHotfileItemsDataPost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileItemsDataPostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Endpoint to upload a file conatining items
     */
    HotfileApi.prototype.apiHotfileItemsFilePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, consumes, canConsumeForm, formParams, useForm, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        consumes = [
                            { contentType: 'multipart/form-data' },
                        ];
                        canConsumeForm = runtime.canConsumeForm(consumes);
                        useForm = false;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        if (useForm) {
                            formParams = new FormData();
                        }
                        else {
                            formParams = new URLSearchParams();
                        }
                        if (requestParameters.files) {
                            requestParameters.files.forEach(function (element) {
                                formParams.append('files', element);
                            });
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/items/file",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: formParams,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Endpoint to upload a file conatining items
     */
    HotfileApi.prototype.apiHotfileItemsFilePost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileItemsFilePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Clears files that is uploaded to blob and table storage but not queued for upload to PIM
     */
    HotfileApi.prototype.apiHotfileUploadClearDeleteRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/upload/clear",
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ClearHotFilesResultFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Clears files that is uploaded to blob and table storage but not queued for upload to PIM
     */
    HotfileApi.prototype.apiHotfileUploadClearDelete = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileUploadClearDeleteRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Endpoint to upload a list of files
     */
    HotfileApi.prototype.apiHotfileUploadPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, consumes, canConsumeForm, formParams, useForm, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.type !== undefined) {
                            queryParameters['type'] = requestParameters.type;
                        }
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        consumes = [
                            { contentType: 'multipart/form-data' },
                        ];
                        canConsumeForm = runtime.canConsumeForm(consumes);
                        useForm = false;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        if (useForm) {
                            formParams = new FormData();
                        }
                        else {
                            formParams = new URLSearchParams();
                        }
                        if (requestParameters.files) {
                            requestParameters.files.forEach(function (element) {
                                formParams.append('files', element);
                            });
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/upload",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: formParams,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Endpoint to upload a list of files
     */
    HotfileApi.prototype.apiHotfileUploadPost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileUploadPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Publishes message to servicebus for user to start uploading hotfiles to PIM
     */
    HotfileApi.prototype.apiHotfileUploadPublishPutRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/upload/publish",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Publishes message to servicebus for user to start uploading hotfiles to PIM
     */
    HotfileApi.prototype.apiHotfileUploadPublishPut = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileUploadPublishPutRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Publishes message to servicebus for user to start uploading hotfiles to PIM
     */
    HotfileApi.prototype.apiHotfileUploadRetryPostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/upload/retry",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Publishes message to servicebus for user to start uploading hotfiles to PIM
     */
    HotfileApi.prototype.apiHotfileUploadRetryPost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileUploadRetryPostRaw(requestParameters, initOverrides)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get a list of all files pending for upload to PIM from the storage account
     */
    HotfileApi.prototype.apiHotfileUploadStatusGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/upload/status",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.TableHotFileStatusFromJSON); })];
                }
            });
        });
    };
    /**
     * Get a list of all files pending for upload to PIM from the storage account
     */
    HotfileApi.prototype.apiHotfileUploadStatusGet = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileUploadStatusGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Endpoint to validate a list of filenames
     */
    HotfileApi.prototype.apiHotfileValidatePostRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.type !== undefined) {
                            queryParameters['type'] = requestParameters.type;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (requestParameters.xMsVersion !== undefined && requestParameters.xMsVersion !== null) {
                            headerParameters['x-ms-version'] = String(requestParameters.xMsVersion);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/api/hotfile/validate",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: requestParameters.requestBody,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.HotFileValiationResultFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Endpoint to validate a list of filenames
     */
    HotfileApi.prototype.apiHotfileValidatePost = function (requestParameters, initOverrides) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiHotfileValidatePostRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return HotfileApi;
}(runtime.BaseAPI));
exports.HotfileApi = HotfileApi;
