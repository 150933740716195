import { scopeMap } from 'auth-config';
import { HotfileApi, PictureType } from 'hotfile-api';
import { createApi } from './api';

const BASE_URL = 'https://' + (process.env.NODE_ENV === 'production' ? document.location.host : 'localhost:7173');
const client = createApi(BASE_URL, scopeMap.hotfolder.scopes, HotfileApi);

const hotfile = client.client as HotfileApi;

const typeToPictureType = (n?: number) => {
    if (n === PictureType.Imagepicture) return PictureType.Imagepicture;
    if (n === PictureType.Productpicture) return PictureType.Productpicture;
    if (n === PictureType.Pdf) return PictureType.Pdf;
    return PictureType.None;
};

const hotfolder = {
    validate: ({ data, type }: { data: Array<string>; type?: number }) =>
        hotfile.apiHotfileValidatePost({ requestBody: data, type: typeToPictureType(type) }),
    upload: ({ data, type, signal }: { data: any; type?: number; signal?: AbortSignal }) =>
        hotfile.apiHotfileUploadPost({ files: data, type: typeToPictureType(type) }, { signal }),
    status: () => hotfile.apiHotfileUploadStatusGet(),
    retry: () => hotfile.apiHotfileUploadRetryPost(),
    publish: () => hotfile.apiHotfileUploadPublishPut(),
    clear: () => hotfile.apiHotfileUploadClearDelete(),
};

export default hotfolder;
