import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Upload } from './HotFolderUpload';

export const LinearProgressWithLabel = ({ count, total, onCancel }: Partial<Upload> & { onCancel?: () => void }) => {
    const { t } = useTranslation();
    const value = (count! / total!) * 100;
    return (
        <Box alignItems="center" flexDirection="column" display="flex">
            <Box sx={{ display: 'flex', alignItems: 'center', width: '500px', mb: onCancel ? 2 : 0 }}>
                <BorderLinearProgress variant="determinate" value={value} />
                <Typography variant="body1" color="text.primary">{`${Math.round(value)}%`}</Typography>
            </Box>
            {onCancel && (
                <Button onClick={onCancel} color="error" variant="contained">
                    {t('upload.cancel', 'Cancel')}
                </Button>
            )}
        </Box>
    );
};

export const BorderLinearProgress = styled(LinearProgress)`
    height: 30px;
    padding: 2px 0;
    border-radius: 5px;
    width: 500px;
    margin-right: 10px;
`;
