"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableHotFileStatusToJSON = exports.TableHotFileStatusFromJSONTyped = exports.TableHotFileStatusFromJSON = exports.instanceOfTableHotFileStatus = void 0;
var runtime_1 = require("../runtime");
var HotfileState_1 = require("./HotfileState");
/**
 * Check if a given object implements the TableHotFileStatus interface.
 */
function instanceOfTableHotFileStatus(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfTableHotFileStatus = instanceOfTableHotFileStatus;
function TableHotFileStatusFromJSON(json) {
    return TableHotFileStatusFromJSONTyped(json, false);
}
exports.TableHotFileStatusFromJSON = TableHotFileStatusFromJSON;
function TableHotFileStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fileName': !(0, runtime_1.exists)(json, 'fileName') ? undefined : json['fileName'],
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : (0, HotfileState_1.HotfileStateFromJSON)(json['state']),
        'date': !(0, runtime_1.exists)(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'minutesInQ': !(0, runtime_1.exists)(json, 'minutesInQ') ? undefined : json['minutesInQ'],
    };
}
exports.TableHotFileStatusFromJSONTyped = TableHotFileStatusFromJSONTyped;
function TableHotFileStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fileName': value.fileName,
        'state': (0, HotfileState_1.HotfileStateToJSON)(value.state),
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'minutesInQ': value.minutesInQ,
    };
}
exports.TableHotFileStatusToJSON = TableHotFileStatusToJSON;
