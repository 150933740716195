import { scopeMap } from 'auth-config';
import { ActiveDeactivateType, HotfileApi } from 'hotfile-api';
import { createApi } from './api';

const BASE_URL = 'https://' + (process.env.NODE_ENV === 'production' ? document.location.host : 'localhost:7173');
const client = createApi(BASE_URL, scopeMap.hotfolder.scopes, HotfileApi);

const api = client.client as HotfileApi;

const swissarmy = {
    upload: ({ files }: { files: Array<any> }) => api.apiHotfileItemsFilePost({ files: files }),
    paste: ({ data }: { data: string; isProductPictures?: boolean }) => api.apiHotfileItemsDataPost({ data: data }),
    activateInactive: ({
        items,
        type,
        key,
    }: {
        items: Array<string> | undefined | null;
        type: ActiveDeactivateType;
        key: string | undefined | null;
    }) => api.apiHotfileItemsActivatePost({ key: key ?? undefined, requestBody: items ?? [], type }),
};

export default swissarmy;
