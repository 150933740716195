import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { scopeMap } from 'auth-config';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import services from 'services';
import { ErrorComponent } from '../components/ErrorComponent';
import { Loading } from '../components/Loading';
import { Profile as ProfileComponent } from '../components/Profile';

const ProfileContent = () => {
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            services.graph
                .me()
                .then((response) => setGraphData(response))
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...scopeMap.login,
                            account: instance.getActiveAccount()!,
                        });
                    }
                });
        }
    }, [inProgress, graphData, instance]);

    return <Box>{graphData ? <ProfileComponent graphData={graphData} /> : null}</Box>;
};

export const Profile = () => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={scopeMap.login}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <ProfileContent />
        </MsalAuthenticationTemplate>
    );
};
