"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearHotFilesResultToJSON = exports.ClearHotFilesResultFromJSONTyped = exports.ClearHotFilesResultFromJSON = exports.instanceOfClearHotFilesResult = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ClearHotFilesResult interface.
 */
function instanceOfClearHotFilesResult(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfClearHotFilesResult = instanceOfClearHotFilesResult;
function ClearHotFilesResultFromJSON(json) {
    return ClearHotFilesResultFromJSONTyped(json, false);
}
exports.ClearHotFilesResultFromJSON = ClearHotFilesResultFromJSON;
function ClearHotFilesResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deletedCount': !(0, runtime_1.exists)(json, 'deletedCount') ? undefined : json['deletedCount'],
        'deletedFiles': !(0, runtime_1.exists)(json, 'deletedFiles') ? undefined : json['deletedFiles'],
    };
}
exports.ClearHotFilesResultFromJSONTyped = ClearHotFilesResultFromJSONTyped;
function ClearHotFilesResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deletedCount': value.deletedCount,
        'deletedFiles': value.deletedFiles,
    };
}
exports.ClearHotFilesResultToJSON = ClearHotFilesResultToJSON;
