import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
}));

export const Page404 = () => {
    return (
        <StyledContent>
            <Typography variant="h3" paragraph>
                404!
            </Typography>
        </StyledContent>
    );
};
