import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

interface Props {
    onDrop: (data: any) => void;
    onPastedText?: (text: string) => void;
    getDataFromClipboardEnabled?: boolean;
    clipboardImageEnabled?: boolean;
    hoverFontSize?: number;
    disabled?: boolean;
    children?: React.ReactNode;
}

const DragAndDrop = ({
    onDrop,
    onPastedText,
    getDataFromClipboardEnabled,
    clipboardImageEnabled = true,
    hoverFontSize,
    disabled,
    children,
}: Props) => {
    const [drag, setDrag] = useState<boolean>(false);
    const dropRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!getDataFromClipboardEnabled) return;

        const handlePaste = (e: ClipboardEvent | any): void => {
            // Ignore paste if certain tagname.
            if (['INPUT'].includes(e?.target?.nodeName)) return;

            // No clip data found.
            if (!e?.clipboardData) return;

            if (
                e?.clipboardData?.files?.length > 0 &&
                (!clipboardImageEnabled ? !e.clipboardData.files[0].type.startsWith('image/') : true)
            ) {
                onDrop(e.clipboardData.files);
            } else if (onPastedText != null) {
                const pastedText = e?.clipboardData?.getData('Text');
                if (pastedText) onPastedText(pastedText);
            }
        };

        window.addEventListener('paste', handlePaste);

        return () => window.removeEventListener('paste', handlePaste);
    }, [getDataFromClipboardEnabled, clipboardImageEnabled, onPastedText, onDrop]);

    useEffect(() => {
        let dragCounter = 0;

        const handleDrag = (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDragIn = (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter++;
            if (e.dataTransfer != null && e?.dataTransfer?.items?.length > 0) {
                setDrag(true);
            }
        };

        const handleDragOut = (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter--;
            if (dragCounter === 0) {
                setDrag(false);
            }
        };

        const handleDrop = (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            setDrag(false);
            if (e.dataTransfer != null && e.dataTransfer.files?.length > 0) {
                onDrop(e.dataTransfer.files);
                e.dataTransfer.clearData();
                dragCounter = 0;
            }
        };

        const div = dropRef?.current;
        if (div != null) {
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
            return () => {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            };
        }
    }, [onDrop]);

    return (
        <DragAndDropWrapper ref={dropRef} isDragging={!!drag} disabled={disabled}>
            <DragOverlay show={drag} fontSize={hoverFontSize}>
                {t('upload.draggingText')}
            </DragOverlay>
            {children}
        </DragAndDropWrapper>
    );
};

export default DragAndDrop;

const DragAndDropWrapper = styled.div<{ isDragging?: boolean; disabled?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
    z-index: ${({ isDragging }) => (isDragging ? 9999 : 0)};
    ${({ disabled }) => disabled && 'pointer-events: none;'};
`;

const DragOverlay = styled.div<{ show?: boolean; fontSize?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.95);
    transition: opacity 0.2s ease, visibility 0.2s ease;
    height: inherit;
    width: inherit;
    visibility: hidden;
    opacity: 0;

    ${({ show }) => show && 'opacity: 1; visibility: visible;'}
`;
