import { MilitaryTech } from '@mui/icons-material';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import Face from '@mui/icons-material/Face';
import { Box, Drawer, Link, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import Avatar from './Avatar';
const navConfig = [
    // {
    //     title: 'Start',
    //     path: '/',
    //     icon: <Home />,
    // },
    {
        title: 'Hot Folder',
        path: '/hotFolder',
        icon: <AddAPhoto />,
    },
    {
        title: 'Swiss Army Knife',
        path: '/swissarmy',
        icon: <MilitaryTech />,
    },
    {
        title: 'Profile',
        path: '/profile',
        icon: <Face />,
    },
];

const NavSection = ({ data = [], ...other }: { data: any[] }) => {
    return (
        <Box {...other}>
            <List disablePadding sx={{ p: 1 }}>
                {data.map((item) => (
                    <NavItem key={item.title} item={item} />
                ))}
            </List>
        </Box>
    );
};

type NavItemProp = {
    item: {
        title: string;
        path: string;
        icon: React.ReactNode;
        info: string;
    };
};

const NavItem = ({ item }: NavItemProp) => {
    const { title, path, icon, info } = item;

    return (
        <ListItemButton
            component={RouterLink}
            to={path}
            sx={{
                '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                },
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
            {info && info}
        </ListItemButton>
    );
};

const NAV_WIDTH = 280;

type Props = {
    openNav: boolean;
    onCloseNav: () => void;
    account?: any;
};

const NavBar = ({ openNav, onCloseNav }: Props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Box
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>{/* <Logo /> */}</Box>
            <Box sx={{ mb: 5, mx: 2.5 }}>
                <Link underline="none">
                    <Avatar />
                </Link>
            </Box>
            <NavSection data={navConfig} />
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={{
                // flexShrink: { lg: 0 },
                // width: { lg: NAV_WIDTH },
                flexShrink: 0,
                width: NAV_WIDTH,
            }}
        >
            {true ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
};

export const StyledNavItemIcon = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
export default NavBar;
