import { graphConfig, scopeMap } from 'auth-config';
import createApi from './api';

const api = createApi(graphConfig.BASE_URL, scopeMap.login.scopes);

const graph = {
    me: () => api.get(graphConfig.graphMeEndpoint),
};

export default graph;
