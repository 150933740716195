"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveDeactiveResultToJSON = exports.ActiveDeactiveResultFromJSONTyped = exports.ActiveDeactiveResultFromJSON = exports.instanceOfActiveDeactiveResult = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ActiveDeactiveResult interface.
 */
function instanceOfActiveDeactiveResult(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfActiveDeactiveResult = instanceOfActiveDeactiveResult;
function ActiveDeactiveResultFromJSON(json) {
    return ActiveDeactiveResultFromJSONTyped(json, false);
}
exports.ActiveDeactiveResultFromJSON = ActiveDeactiveResultFromJSON;
function ActiveDeactiveResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'item': !(0, runtime_1.exists)(json, 'item') ? undefined : json['item'],
        'result': !(0, runtime_1.exists)(json, 'result') ? undefined : json['result'],
    };
}
exports.ActiveDeactiveResultFromJSONTyped = ActiveDeactiveResultFromJSONTyped;
function ActiveDeactiveResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.ActiveDeactiveResultToJSON = ActiveDeactiveResultToJSON;
