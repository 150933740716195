import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Avatar, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scopeMap } from '../auth-config';
import { AccountPicker } from './AccountPicker';

export const SignInSignOutButton = () => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        return <SignOutButton />;
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return <SignInButton />;
    } else {
        return null;
    }
};

export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleLogin = () => {
        instance.loginRedirect(scopeMap.login);
    };

    return (
        <Button onClick={() => handleLogin()} color="inherit">
            {t('general.signIn')}
        </Button>
    );
};

export const SignOutButton = () => {
    const { instance } = useMsal();
    const [accountSelectorOpen, setOpen] = useState(false);
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const open = Boolean(anchorEl);

    const handleLogout = () => {
        setAnchorEl(null);
        instance.logoutRedirect();
    };

    const handleAccountSelection = () => {
        setAnchorEl(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box sx={{ cursor: 'pointer', width: 'fit-content' }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <Avatar />
            </Box>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleAccountSelection()} key="switchAccount">
                    {t('general.switchAccount')}
                </MenuItem>
                <MenuItem onClick={() => handleLogout()} key="logoutRedirect">
                    {t('general.logout')}
                </MenuItem>
            </Menu>
            <AccountPicker open={accountSelectorOpen} onClose={handleClose} />
        </div>
    );
};
