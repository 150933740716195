import { createGlobalStyle } from 'styled-components/macro';

const Global = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
  }

  #root {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
  }
`;

export default Global;
