export default function chunkIt<T>(items: Array<T>, size: number) {
    const chunks = [];
    items = [...items];

    while (items.length) {
        chunks.push(items.splice(0, size));
    }

    return chunks;
}
