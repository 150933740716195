import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SignInSignOutButton } from './SignInSignOutButton';

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '1.6rem 2rem',
    borderRadius: 3,
    backgroundColor: 'rgba(145, 158, 171, 0.12)',
}));

const Avatar = () => {
    const { instance } = useMsal();
    const [name, setName] = useState<string | null>(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount && activeAccount.name) {
            setName(activeAccount.name.split(' ')[0]);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    return (
        <StyledAccount>
            <SignInSignOutButton></SignInSignOutButton>

            {name && (
                <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                        {name}
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }}></Typography>
                </Box>
            )}
        </StyledAccount>
    );
};

export default Avatar;
