import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // debug: true,
        lng: 'en',
        fallbackLng: 'en',
        returnEmptyString: false,
        resources: {
            en: {
                translation: {
                    general: {
                        image: 'Image',
                        picture: 'Picture',
                        logout: 'Logout',
                        hotFolder: ' Hot Folder',
                        profile: 'Profile',
                        signIn: 'Sign in',
                        switchAccount: 'Switch Account',
                    },
                    authentication: {
                        fail: 'You are not authenticated',
                        success: 'You are authenticated',
                    },
                    upload: {
                        '5mbSizeExceededError': 'The file can be a maximum of 5MB.',
                        allowedType: 'Allowed file types',
                        attachments: 'Attachments',
                        dragAndDropOrBrowse: 'Drag and drop or <u>browse</u>',
                        draggingText: 'Release files here to upload',
                        file_other: 'Files',
                        filename: 'Filename',
                        none: 'No files has been uploaded yet. Add some files!',
                        processingFileUpload: 'Processing file upload...',
                        supported: '.jpg, .jpeg, .png, .psd, .svg',
                        supportedXlsx: '.xlsx, .txt, .csv',
                        cancel: 'Cancel',
                        uploadType: 'Upload type',
                        upload: 'Upload',
                        operationTypeCompany: 'Company',
                        operationTypeChannel: 'Channel',
                        operationType: 'Operation',
                    },
                    publish: {
                        nextRefresh: 'Next refresh at: ',
                        refresh: 'Auto Refresh',
                        none: 'Nothing to publish..',
                        publish: 'Publish',
                        awaitPublish: 'Awaiting publish',
                        pending: 'Queued to PIM',
                        inprogress: 'Uploading to PIM',
                        error: 'Error when uploading to PIM',
                        uploaded: 'Uploaded to PIM',
                        cancel: 'Cancel',
                        clear: 'Clear',
                        dialogDescription: 'Are you sure?',
                    },
                },
            },
        },
    });

export default i18n;
