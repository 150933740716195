"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveDeactivateTypeToJSON = exports.ActiveDeactivateTypeFromJSONTyped = exports.ActiveDeactivateTypeFromJSON = exports.ActiveDeactivateType = void 0;
/**
 *
 * @export
 */
exports.ActiveDeactivateType = {
    ActivateCompany: 0,
    DeactivateCompany: 2,
    ActivateChannel: 3,
    DeactivateChannel: 4
};
function ActiveDeactivateTypeFromJSON(json) {
    return ActiveDeactivateTypeFromJSONTyped(json, false);
}
exports.ActiveDeactivateTypeFromJSON = ActiveDeactivateTypeFromJSON;
function ActiveDeactivateTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ActiveDeactivateTypeFromJSONTyped = ActiveDeactivateTypeFromJSONTyped;
function ActiveDeactivateTypeToJSON(value) {
    return value;
}
exports.ActiveDeactivateTypeToJSON = ActiveDeactivateTypeToJSON;
