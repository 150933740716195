"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotFileValiationResultToJSON = exports.HotFileValiationResultFromJSONTyped = exports.HotFileValiationResultFromJSON = exports.instanceOfHotFileValiationResult = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the HotFileValiationResult interface.
 */
function instanceOfHotFileValiationResult(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfHotFileValiationResult = instanceOfHotFileValiationResult;
function HotFileValiationResultFromJSON(json) {
    return HotFileValiationResultFromJSONTyped(json, false);
}
exports.HotFileValiationResultFromJSON = HotFileValiationResultFromJSON;
function HotFileValiationResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : json['errors'],
        'itemsToCreate': !(0, runtime_1.exists)(json, 'itemsToCreate') ? undefined : json['itemsToCreate'],
        'fileNamesValid': !(0, runtime_1.exists)(json, 'fileNamesValid') ? undefined : json['fileNamesValid'],
    };
}
exports.HotFileValiationResultFromJSONTyped = HotFileValiationResultFromJSONTyped;
function HotFileValiationResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errors': value.errors,
        'itemsToCreate': value.itemsToCreate,
        'fileNamesValid': value.fileNamesValid,
    };
}
exports.HotFileValiationResultToJSON = HotFileValiationResultToJSON;
