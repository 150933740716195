import { Box } from '@mui/system';
import React from 'react';
import styled from 'styled-components/macro';
import NavBar from './NavBar';

type Props = {
    children: React.ReactNode;
};

export const PageLayout = ({ children }: Props) => {
    return (
        <StyledLayout>
            <NavBar openNav={false} onCloseNav={() => {}} />
            <Box sx={{ padding: '1rem', flex: 1 }}>{children}</Box>
        </StyledLayout>
    );
};

const StyledLayout = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    backgroundColor: 'rgb(249, 250, 251)',
});
