import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box, Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import { scopeMap } from 'auth-config';
import { ErrorComponent } from 'components/ErrorComponent';
import HotFolderPublish from 'components/HotFolderPublish';
import HotFolderUpload from 'components/HotFolderUpload';
import { Loading } from 'components/Loading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};
const HotfolderContent = () => {
    const [tab, setTab] = useState<string>('upload');
    const { t } = useTranslation();
    return (
        <Box sx={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Box sx={{ marginBottom: 2 }}>
                <Tabs value={tab} onChange={(_val, tab) => setTab(tab)} aria-label="tab">
                    <Tab label={t('upload.upload')} value={'upload'} />
                    <Tab label={t('publish.publish')} value={'publish'} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={'upload'}>
                <HotFolderUpload />
            </TabPanel>
            <TabPanel value={tab} index={'publish'}>
                <HotFolderPublish tab={tab} />
            </TabPanel>
        </Box>
    );
};

export const HotFolder = () => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={scopeMap.hotfolder}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <HotfolderContent />
        </MsalAuthenticationTemplate>
    );
};
