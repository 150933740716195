import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t } = useTranslation();
    return (
        <>
            <AuthenticatedTemplate>{t('authentication.success')}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>{t('authentication.fail')}</UnauthenticatedTemplate>
        </>
    );
};
