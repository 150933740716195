"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.PimSync.Hotfile.Api
 * API for Hotfile handling
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PictureTypeToJSON = exports.PictureTypeFromJSONTyped = exports.PictureTypeFromJSON = exports.PictureType = void 0;
/**
 *
 * @export
 */
exports.PictureType = {
    Productpicture: 0,
    Imagepicture: 1,
    Pdf: 2,
    None: 3
};
function PictureTypeFromJSON(json) {
    return PictureTypeFromJSONTyped(json, false);
}
exports.PictureTypeFromJSON = PictureTypeFromJSON;
function PictureTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PictureTypeFromJSONTyped = PictureTypeFromJSONTyped;
function PictureTypeToJSON(value) {
    return value;
}
exports.PictureTypeToJSON = PictureTypeToJSON;
