import graph from './graph';
import hotfolder from './hotfolder';
import swissarmy from './swissarmy';

const index = {
    hotfolder: hotfolder,
    swissarmy: swissarmy,
    graph: graph,
};

export default index;
