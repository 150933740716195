import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

export const Profile = ({ graphData }: any) => {
    return (
        <List>
            <ListRow primaryText="Name" secondaryText={graphData.displayName} icon={<PersonIcon />} />
            <ListRow primaryText="Title" secondaryText={graphData.jobTitle} icon={<WorkIcon />} />
            <ListRow primaryText="Mail" secondaryText={graphData.mail} icon={<MailIcon />} />
            <ListRow primaryText="Phone" secondaryText={graphData.businessPhones[0]} icon={<PhoneIcon />} />
            <ListRow primaryText="Location" secondaryText={graphData.officeLocation} icon={<LocationOnIcon />} />
        </List>
    );
};

const ListRow = ({
    primaryText,
    secondaryText,
    icon,
}: {
    primaryText: string;
    secondaryText: string;
    icon: React.ReactNode;
}) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItem>
);
