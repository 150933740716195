import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { HotfileState, TableHotFileStatus } from 'hotfile-api';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import services from 'services';
import HotFolderPublishList from './HotFolderPublishList';

const HotFolderPublish = ({ tab }: { tab: string }) => {
    const { inProgress } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<TableHotFileStatus[]>([]);
    const { t } = useTranslation();
    const [autoRefreshOn, setAutoRefreshOn] = useState<boolean>(false);
    const [time, setTime] = useState<Date | undefined>(undefined);

    const fetchStatus = useCallback(() => {
        if (inProgress === InteractionStatus.None) {
            setLoading(true);
            services.hotfolder
                .status()
                .then((res: TableHotFileStatus[]) => {
                    setStatus(res);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [inProgress]);

    const publish = useCallback(() => {
        if (inProgress === InteractionStatus.None) {
            setLoading(true);
            services.hotfolder
                .publish()
                .then(() => {
                    fetchStatus();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [inProgress, fetchStatus]);

    const clear = useCallback(() => {
        if (inProgress === InteractionStatus.None) {
            setLoading(true);
            services.hotfolder
                .clear()
                .then(({ deletedFiles }) => {
                    setStatus((prev) => {
                        return prev.filter((file) => !deletedFiles?.includes(file.fileName ?? ''));
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [inProgress]);

    const retry = useCallback(() => {
        if (inProgress === InteractionStatus.None) {
            setLoading(true);
            services.hotfolder
                .retry()
                .then(() => {
                    fetchStatus();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [inProgress]);

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined;
        if (autoRefreshOn) {
            fetchStatus();
            setTime(new Date(Date.now() + 3000));
            interval = setInterval(() => {
                setTime(new Date(Date.now() + 3000));
                fetchStatus();
            }, 5000);
        } else if (!autoRefreshOn) {
            clearInterval(interval);
            setTime(undefined);
        }
        return () => {
            clearInterval(interval);
            setTime(undefined);
        };
    }, [autoRefreshOn, fetchStatus]);

    useEffect(() => {
        fetchStatus();
    }, [tab, fetchStatus]);

    useEffect(() => {
        const autoRefresh =
            status.filter((s) => s.state === HotfileState.InProgress || s.state == HotfileState.Pending).length > 0;
        setAutoRefreshOn(autoRefresh);
    }, [status]);

    return (
        <Stack spacing={2}>
            <Box>
                {autoRefreshOn && (
                    <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
                        {/* <Button
                        variant="contained"
                        color={autoRefreshOn ? 'warning' : 'primary'}
                        size="small"
                        onClick={() => setAutoRefreshOn(!autoRefreshOn)}
                        disabled={loading}
                        sx={{ marginRight: '0.5rem' }}
                    >
                        {t('publish.refresh', 'Refresh')}
                    </Button> */}
                        <Typography sx={{ padding: '3px' }}>
                            {time && `${t('publish.nextRefresh')} ${time.toLocaleTimeString()}`}
                        </Typography>
                    </Box>
                )}

                <HotFolderPublishList status={status} clear={clear} retry={retry} publish={publish} />
            </Box>
        </Stack>
    );
};

export default HotFolderPublish;
