import { MsalProvider } from '@azure/msal-react';
import Grid from '@mui/material/Grid';
import { Page404 } from 'pages/Page404';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import { Home } from './pages/Home';
import { HotFolder } from './pages/HotFolder';
import { SwissArmy } from './pages/SwissArmy';
import { Profile } from './pages/Profile';

import { PublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/NavigationClient';

function App({ pca }: { pca: PublicClientApplication }) {
    return (
        <ClientSideNavigation pca={pca}>
            <MsalProvider instance={pca}>
                <PageLayout>
                    <Grid container>
                        <Pages />
                    </Grid>
                </PageLayout>
            </MsalProvider>
        </ClientSideNavigation>
    );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({ pca, children }: { pca: PublicClientApplication; children: JSX.Element }) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
}

function Pages() {
    return (
        <Routes>
            <Route path="/hotFolder" element={<HotFolder />} />
            <Route path="/hotFolder2" element={<HotFolder />} />
            <Route path="/swissarmy" element={<SwissArmy />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}

export default App;
