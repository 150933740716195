import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { CheckCircleOutline } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    ListItemText,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { HotFileValiationResult } from 'hotfile-api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import services from 'services';
import styled from 'styled-components/macro';
import formatBytes from 'utils/formatBytes';
import FileUpload from './FileUpload';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

let controller: AbortController;
let signal: AbortSignal;

type Validation = HotFileValiationResult & { failed?: boolean };

export type Upload = {
    inProgress: boolean;
    count: number;
    total: number;
    aborted: boolean;
};

const getUploadType = (value: number) => {
    switch (value) {
        case 0:
            return 'product';
        case 1:
            return 'image';
        case 2:
            return 'pdf';
    }
};

const uploadTypeChange = (setType: (x: number) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
        case 'product':
            setType(0);
            break;
        case 'image':
            setType(1);
            break;
        case 'pdf':
            setType(2);
            break;
    }
    return '';
};

const HotFolderUpload = () => {
    const { instance, inProgress } = useMsal();
    const fileInput = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [validation, setValidation] = useState<Validation | null>({
        errors: undefined,
        itemsToCreate: [],
        fileNamesValid: false,
        failed: false,
    });
    const [upload, setUpload] = useState<Upload>({
        inProgress: false,
        count: 0,
        total: 0,
        aborted: false,
    });

    const [count, setCount] = useState<number>(0);
    const [type, setType] = useState<number>(0);
    const [validating, setValidating] = useState<boolean>(false);
    //console.log('disabled', !validation?.fileNamesValid || validating || files.length === 0);
    //console.log('file', files.length === 0);
    //console.log('validating', validating);
    //console.log('fileNamesValid', validation?.fileNamesValid);
    //console.log('validation', validation);
    const { t } = useTranslation();

    const validateFileNames = useCallback(
        (retry: boolean = true) => {
            if (files && files.length > 0 && inProgress === InteractionStatus.None && files.length > count) {
                setValidating(true);
                services.hotfolder
                    .validate({ data: files.map((file) => file.name), type })
                    .then((response: any) => {
                        const filesTooLarge = files
                            .filter((file) => file.size > 200000000)
                            .reduce(
                                (acc, file) => ({
                                    ...acc,
                                    [`${file.name}`]: [
                                        t('upload.fileTooLarge', 'File too large. Max filesize is 200 MB'),
                                        ...(response[file.name] ? response[file.name] : []),
                                    ],
                                }),
                                {},
                            );
                        const validation = {
                            ...response,
                            errors: { ...response.errors, ...filesTooLarge },
                            fileNamesValid: Object.keys(filesTooLarge).length > 0 ? false : response.fileNamesValid,
                            failed: false,
                        };
                        setValidation(validation);
                        setValidating(false);
                    })
                    .catch(() => {
                        if (retry) return validateFileNames(false);
                        setValidation((prev) => ({ ...prev, failed: true }));
                        setValidating(false);
                    });
            }
        },
        [files, inProgress, count, type],
    );

    const addFile = useCallback(async (addFiles: FileList) => {
        if (!(addFiles.length > 0)) {
            return;
        }

        await setFiles((prev) => {
            const existingFiles = prev.map((file) => file.name);
            const files = [...addFiles].reduce(
                (acc: { update: File[]; add: File[] }, file: File) => {
                    if (!existingFiles.includes(file.name)) {
                        acc.add.push(file);
                    }

                    if (existingFiles.includes(file.name)) {
                        const replaceIndex = existingFiles.findIndex((existingFile) => existingFile === file.name);
                        acc.update.splice(replaceIndex, 1, file);
                    }

                    return acc;
                },
                { update: [...prev], add: [] },
            );

            return [...files.update, ...files.add];
        });
    }, []);

    const uploadFiles = useCallback(async () => {
        controller = new AbortController();
        signal = controller.signal;

        setUpload((prev) => ({
            ...prev,
            inProgress: true,
            aborted: false,
            total: files.reduce((acc, file) => acc + (file.size ?? 0), 0),
            count: 0,
        }));

        (function sequenceUpload(array, isRetry?: boolean) {
            let files = [...array];

            if (files.length === 0) {
                setTimeout(
                    () =>
                        setUpload((prev) => ({
                            ...prev,
                            inProgress: false,
                        })),
                    1000,
                );
                return;
            }

            let shouldRetry = false;
            const file = files.shift();

            return services.hotfolder
                .upload({ data: [file!], type, signal })
                .then(() => {
                    setFiles((prev) => prev.filter((prevFile) => prevFile.name !== file?.name));
                })
                .catch((err) => {
                    if (!isRetry) {
                        shouldRetry = true;
                    }
                })
                .finally(async () => {
                    if (!shouldRetry) {
                        await setUpload((prev) => ({
                            ...prev,
                            count: prev.count + (file?.size ?? 0),
                        }));
                    }

                    if (signal.aborted) return;
                    sequenceUpload(shouldRetry ? [file!, ...files].filter((i) => i) : files, shouldRetry);
                });
        })(files);
    }, [files, type]);

    const setInputFiles = useCallback((files: File[]) => {
        if (!fileInput.current) {
            return;
        }

        const dataTransfer = new DataTransfer();
        files.map((file) => dataTransfer.items.add(file));
        fileInput.current.files = dataTransfer.files;
    }, []);

    useEffect(() => {
        validateFileNames(true);

        if (files.length === 0) {
            setValidation(null);
        }

        if (files.length < count) {
            setValidation((prev) => {
                const fileNamesAsArray = files.map((file) => file.name);
                const errors = Object.keys(prev?.errors ?? {}).reduce((acc, key) => {
                    if (fileNamesAsArray.includes(key)) {
                        return {
                            ...acc,
                            [key]: prev?.errors?.[key],
                        };
                    }

                    return {
                        ...acc,
                    };
                }, {});
                return {
                    ...prev,
                    errors,
                    fileNamesValid: Object.keys(errors).length === 0,
                } as Validation;
            });
        }

        setCount(files.length);
        setInputFiles(files);
    }, [files, instance, inProgress, type, count, validateFileNames, setInputFiles]);

    return (
        <Box>
            <Box sx={{ marginBottom: '2rem', display: 'flex', flexDirection: 'column' }}>
                <FormControl sx={{ marginBottom: '2rem' }} disabled={files.length > 0}>
                    <FormLabel id="demo-row-radio-buttons-group-label">{t('upload.uploadType')}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={getUploadType(type)}
                        onChange={uploadTypeChange(setType)}
                    >
                        <FormControlLabel value="product" control={<Radio />} label="Product Picture" />
                        <FormControlLabel value="image" control={<Radio />} label="Image Picture" />
                        <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                    </RadioGroup>
                </FormControl>
                <StyledFileUpload
                    fileInputRef={fileInput}
                    handleDrop={addFile}
                    uploadFiles={addFile}
                    getDataFromClipboardEnabled
                    clipboardImageEnabled={false}
                    multiple={true}
                    allowedFiletypes={
                        (type === 2
                            ? t('upload.supportedPdf', '.pdf')
                            : t('upload.supported', '.jpg, .jpeg, .png, .psd, .svg')) ?? ''
                    }
                />
                <Box sx={{ maxWidth: 1000, display: 'flex' }}>
                    <Box sx={{ marginRight: 'auto' }}>
                        <Button
                            disabled={!validation?.fileNamesValid || validating || files.length === 0}
                            variant="contained"
                            color="success"
                            size="small"
                            type="submit"
                            onClick={uploadFiles}
                        >
                            {t('upload.upload', 'Upload')}
                        </Button>
                    </Box>
                    <Button
                        disabled={files.length === 0 || validating}
                        color="error"
                        size="small"
                        type="submit"
                        startIcon={<DeleteIcon color="inherit" />}
                        onClick={() => setFiles([])}
                    >
                        {t('upload.clear', 'Clear')}
                    </Button>
                </Box>
            </Box>

            {validation?.failed && (
                <Alert severity="error">
                    <AlertTitle>{t('upload.error', 'Validation error')}</AlertTitle>
                    <Box sx={{ mb: 2 }}>
                        {t(
                            'upload.revalidate_description',
                            'Something went wrong with validating filenames. Try again.',
                        )}
                    </Box>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            setCount(0);
                            setValidation(null);
                            validateFileNames();
                        }}
                    >
                        {t('upload.revalidate', 'Revalidate')}
                    </Button>
                </Alert>
            )}
            <Box>
                <StyledTableContainer disabled={validation?.failed}>
                    <TableContainer component={Box}>
                        <Table sx={{ maxWidth: 1000 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '10%' }}>{t('upload.table_no', 'No.')}</TableCell>
                                    <TableCell sx={{ width: '12.5%' }}>{t('upload.table_valid', 'Valid')}</TableCell>
                                    <TableCell sx={{ width: '50%' }}>
                                        {t('upload.table_filename', 'Filename')}
                                    </TableCell>
                                    <TableCell sx={{ width: '12.5%' }}>{t('upload.table_size', 'Size')}</TableCell>
                                    <TableCell sx={{ width: '10%' }} align="right"></TableCell>
                                </TableRow>
                            </TableHead>

                            {validating ? (
                                <TableBody>
                                    <TableRow sx={{ border: 0 }}>
                                        <TableCell colSpan={3} sx={{ border: 0, alignItems: 'center' }}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : files.length === 0 ? null : (
                                <TableBody>
                                    {files.map((file, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ width: '10%' }} scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell sx={{ width: '12.5%' }} scope="row">
                                                {validation?.failed ? (
                                                    <CancelIcon sx={{ color: grey[400], marginRight: '0.5rem' }} />
                                                ) : validation?.fileNamesValid || !validation?.errors?.[file.name] ? (
                                                    <CheckCircleOutline
                                                        sx={{ color: green.A700, marginRight: '0.5rem' }}
                                                    />
                                                ) : (
                                                    <CancelIcon sx={{ color: red[600], marginRight: '0.5rem' }} />
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ width: '50%' }}>
                                                <ListItemText
                                                    key={index}
                                                    sx={{ wordBreak: 'break-all' }}
                                                    primary={file.name}
                                                    secondary={
                                                        validation?.fileNamesValid
                                                            ? ''
                                                            : validation?.errors?.[file.name]
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: '12.5%' }}>{formatBytes(file.size, 1)}</TableCell>
                                            <TableCell sx={{ width: '10%' }} align="right">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                    color="error"
                                                    onClick={() =>
                                                        setFiles((prev) =>
                                                            prev.filter((item) => item.name !== file.name),
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </StyledTableContainer>
            </Box>

            {upload.inProgress && (
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={upload.inProgress}>
                    <Box sx={{ background: '#fff', borderRadius: 2, p: 2 }}>
                        {t('upload.uploading', 'Uploading')}
                        <LinearProgressWithLabel
                            inProgress={true}
                            count={upload.count}
                            total={upload.total}
                            onCancel={() => {
                                controller.abort();
                                setUpload((prev) => ({ ...prev, inProgress: false, aborted: true }));
                            }}
                        />
                    </Box>
                </Backdrop>
            )}
        </Box>
    );
};

const StyledFileUpload = styled(FileUpload)`
    padding: 1rem;
    margin-bottom: 1rem;
    width: 700px;
    height: 230px;
`;

const StyledTableContainer = styled.div<{ disabled?: boolean }>`
    ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.3'}
`;
export default HotFolderUpload;
