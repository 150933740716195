import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { ThemeProvider } from '@mui/material/styles';
import { msalConfig } from 'auth-config';
import 'i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from 'styles/globalStyles';
import App from './App';
import { theme } from './styles/theme';
export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
    const { payload } = event;
    if (event.eventType === EventType.LOGIN_SUCCESS && payload && 'account' in payload) {
        const account = payload.account;
        msalInstance.setActiveAccount(account!);
    }
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <App pca={msalInstance} />
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
);
